<template>
  <div class="elv-transactions-trade-detail-page">
    <Header
      ref="transactionsHeaderRef"
      type="TRANSACTIONS"
      :title="t('project.nav.transactions')"
      updatedOn=""
      :showRegenerate="false"
      :showRegenerateButton="true"
    />

    <HeaderTabs
      :showJournalButton="false"
      :showIntegrations="false"
      @onOpenIntegrationDialog="onOpenIntegrationDialog"
    />

    <TransactionScreening
      ref="transactionScreeningRef"
      tableType="transaction"
      :exportParams="exportParams"
      :filterType="transactionFilterSaveType.TRADE"
      @onChangeSort="onChangeSort"
      @onFilterTradeData="onFilterTradeData"
    />
    <ExportDataTaskHistoryInfo :exportType="exportProgressStatusType.trade" />
    <div class="elv-transactions-trade-table-list">
      <TableList
        ref="listTableRef"
        :tableData="tradeTableData"
        :pageData="tradeParams"
        :tableLoading="tableLoading"
        @onChangePage="onChangePage"
        @onChangePageSize="onChangePageSize"
      />
    </div>
  </div>
  <IntegrationDialog ref="integrationDialogRef" @onResetList="onChangePage(1)" />
</template>
<script lang="ts" setup>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import { cloneDeep, isEmpty } from 'lodash-es'
import Header from '../../components/Header.vue'
import TableList from './components/TableList.vue'
import HeaderTabs from '../components/HeaderTabs.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { exportProgressStatusType } from '@/config/global'
import { transactionFilterSaveType } from '@/config/transactions'
import TransactionScreening from '../components/ListScreening.vue'
import { useTransactionStore } from '@/stores/modules/transactions'
import ExportDataTaskHistoryInfo from '../../components/ExportDataTaskHistoryInfo.vue'
import IntegrationDialog from '../../Ledger/Journals/components/IntegrationDialog/Index.vue'

dayjs.extend(utc)
const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const transactionStore = useTransactionStore()

const tableLoading = ref(true)
const listTableRef = ref()
const integrationDialogRef = useTemplateRef('integrationDialogRef')
const transactionsHeaderRef = useTemplateRef<InstanceType<typeof Header>>('transactionsHeaderRef')
const transactionScreeningRef = useTemplateRef('transactionScreeningRef')

const tradeParams = ref({
  page: 1,
  limit: 20,
  sortField: 'datetime',
  sortDirection: 'DESC'
})

const tradeTableData: any = ref({
  total: {
    counterAmountFC: 0,
    feeAmountFC: 0
  },
  totalCount: 0,
  list: [],
  filteredCount: 0
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const exportParams = computed(() => {
  const params: any = {
    type: exportProgressStatusType.trade.value,
    extra: {
      sortField: 'datetime',
      sortDirection: tradeParams.value.sortDirection
    }
  }

  const currentFilterData = transactionStore.entityFilterList.find((item: any) => {
    return item.type === transactionFilterSaveType.TRADE
  })
  const currentSaveList = currentFilterData?.list || []
  const entityFilter = currentSaveList.find((item: any) => {
    return item.entityId === entityId.value
  })
  if (!isEmpty(entityFilter)) {
    params.extra = {
      ...params.extra,
      ...entityFilter.data
    }
    if (entityFilter.data?.currency?.length) {
      params.extra.currency = entityFilter.data?.currency.map((item: string) => {
        return item.split('__')[1] ?? item
      })
    }
  }
  return params
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

/**
 * 获取Trade列表数据
 */
const getTradeTableData = async () => {
  try {
    tableLoading.value = true
    let params: Record<string, unknown> = {
      ...tradeParams.value
    }
    const currentFilterData = transactionStore.entityFilterList.find((item: any) => {
      return item.type === transactionFilterSaveType.TRADE
    })
    const currentSaveList = currentFilterData?.list || []
    const entityFilter = currentSaveList.find((item: any) => {
      return item.entityId === entityId.value
    })
    if (!isEmpty(entityFilter)) {
      params = {
        ...params,
        ...entityFilter.data
      }
      if (entityFilter.data?.currency?.length) {
        params.currency = entityFilter.data?.currency.map((item: string) => {
          return item.split('__')[1] ?? item
        })
      }
      if (entityFilter.data?.journalTypeIds?.length) {
        params.journalTypeIds = entityFilter.data?.journalTypeIds.filter((item: string) => {
          return (
            item === '0' ||
            transactionStore.journalTypeList.find((journalType: any) => {
              return journalType.journalTypeId === item
            })
          )
        })
        const filterData: any = cloneDeep(entityFilter.data)
        filterData.journalTypeIds = params.journalTypeIds
        transactionStore.editEntityFilter(entityId.value, filterData, transactionFilterSaveType.TRADE)
      }
    }
    const response = await transactionStore.fetchTradeList(entityId.value, params)
    tradeTableData.value = response
    listTableRef.value?.ref?.setScrollTop(0)
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    tableLoading.value = false
  }
}

/**
 * 打开OpenAPI 集成弹窗
 */
const onOpenIntegrationDialog = () => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.businessData?.view
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  integrationDialogRef.value?.onCheckJournalIntegrationDialog()
}

/**
 * 分页器改变页码触发
 * @param page 当前页码
 */
const onChangePage = (page: number) => {
  tradeParams.value.page = page
  getTradeTableData()
}

/**
 * 分页器改变每页条数触发
 * @param pageSize 当前每页条数
 */
const onChangePageSize = (pageSize: number) => {
  tradeParams.value.limit = pageSize
  tradeParams.value.page = 1
  getTradeTableData()
}

/**
 * 更换排序类型--时间
 * @param sort 排序类型 DESC/ASC
 */
const onChangeSort = (sort: string) => {
  tradeParams.value.sortDirection = sort
  tradeParams.value.page = 1
  getTradeTableData()
}

/**
 * 筛选交易数据
 */
const onFilterTradeData = () => {
  tradeParams.value.page = 1
  getTradeTableData()
}

watch(
  () => route,
  async (newValue) => {
    try {
      if (newValue.name === 'entity-transactions-trade-list') {
        transactionStore.transactionFilterDataInit(entityId.value, 'Trade')
        await getTradeTableData()
      }
    } catch (error) {
      console.log(error)
    }
  },
  { immediate: true }
)
</script>
<style lang="scss" scoped>
.elv-transactions-trade-detail-page {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .elv-transactions-trade-table-list {
    flex: 1;
    min-height: 0px;
    position: relative;
  }
}
</style>
